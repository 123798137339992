import React from 'react';
import Carousel from 'react-elastic-carousel';
import Swal from 'sweetalert2';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

import useWindowSize from 'hooks/useWindowSize';
import { cartItemsArrayPropTypes } from 'helpers/propTypes';
import history from 'helpers/history';
import { getLocalStorage } from 'helpers/storage';

import Modal from 'components/commons/Modal';
import Breadcrumb from 'components/commons/Breadcrumb';
import QuantitySelector from 'components/commons/QuantitySelector';
import Button from 'components/commons/Button';
import Loading from 'components/commons/Loading';

import remove from './assets/remove.svg';

import './Cart.scss';

const Cart = (props) => {
  const {
    clearCart, cartSize, isModalCart, items, loading, minimumPurchase, onClose, onItemClick,
    onMessageChange, onQuantityChange, onRemoveFromCart, priceDiscount, subtotal,
  } = props;

  const clearCartModal = () => {
    Swal.fire({
      title: '¿Estás seguro que deseas vaciar el carrito?',
      text: '¡Perderás todo lo seleccionado!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: '¡Se vació tu carrito!',
          icon: 'success',
          confirmButtonColor: '#ff532b',
          confirmButtonText: 'Aceptar',
        });
        clearCart();
      }
    });
  };
  const size = useWindowSize();
  const showCart = cartSize > 0 && cartSize;
  const pushRoute = (route) => {
    if (onClose) onClose();
    history.push(route);
  };
  const dots = (word) => (word.length > 45 ? `${word.substring(0, 45)}...` : word);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 151, itemsToShow: 2 },
  ];

  const renderEmptyCart = () => (
    <div className="no-realizo carrito-empty">
      <span data-testid="empty-cart">
        El carrito está vacío
      </span>
    </div>
  );

  const renderCartComments = (item, quantity, message) => (
    <div className="cart-comments">
      <span className="cart-item-title">
        {dots(item.name)}
      </span>
      <TextField
        label=""
        placeholder="Suma un comentario.."
        value={message}
        onChange={(value) => onMessageChange(item, quantity, null, value.target.value)}
        id="margin-none"
        style={{
          width: '100%', borderColor: 'yellow', border: '1px solid #CACACA', borderRadius: '10px', padding: '0px 0px 0px 5px', height: '35px',
        }}
      />
    </div>
  );

  const renderCheckoutDetails = () => (
    <>
      <div className="row" data-testid="checkout-details">
        <div className="col">
          <div className="checkout">
            <ul className="checkout-details">
              <li>
                <span className="span-subtotal">Subtotal:</span>
                {priceDiscount === null ? (
                  <span className="float-right span-subtotal">
                    {`$${subtotal.toLocaleString('de-DE')}`}
                  </span>
                ) : (
                  <del className="float-right span-subtotal">
                    {`$${subtotal.toLocaleString('de-DE')}`}
                  </del>
                )}
              </li>
              {priceDiscount != null ? (
                <li>
                  <span className="span-descuentos descuento-automatico">
                    Descuento automático de
                    {' '}
                    {getLocalStorage('percentage')}
                    %
                  </span>
                  <span className="float-right span-subtotal">
                    -$
                    {(subtotal - priceDiscount).toLocaleString('de-DE')}
                  </span>
                </li>
              ) : ''}
              <li>
                <span className="cart-checkout-title">Total</span>
                {priceDiscount === null ? (
                  <span className="float-right cart-checkout-price">
                    {`$${subtotal.toLocaleString('de-DE')}`}
                  </span>
                ) : (
                  <span className="float-right cart-checkout-price">
                    {`$${priceDiscount.toLocaleString('de-DE')}`}
                  </span>
                )}

              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <Button text="Vaciar carrito" buttonClass="empty-button" onClick={() => clearCartModal()} />
        </div>
        <div className="col-lg-4">
          <Button text="Seguir viendo productos" buttonClass="checkout-button" onClick={() => pushRoute('/productos')} />
        </div>
        <div className="col-lg-4">
          {minimumPurchase ? <Button text="Continuar con la compra" buttonClass="checkout-button" onClick={() => pushRoute('/checkout')} /> 
            :
            <div className="monto-min-cart" style={{height:"100%",alignItems:"center",display:"flex",justifyContent:"center"}}>
                  <span style={{color:"red",fontSize:"18px"}}>
                    Necesita actualizar la web para comprar
                  </span>
                </div>
          }
        </div>
      </div>
    </>
  );

  const renderDescriptionCarousel = (description, item, message) => (
    <div key={`cart-description-${item.id}-${description.stock_description_id}`}>
      <span className="cart-item-description">
        { description.stock_descriptions.rgb ? (
          <div className="input-color">
            {/* <strong className="">{description.stock_descriptions.name}</strong> */}
            <span className="color" style={{ backgroundColor: description.stock_descriptions.rgb }} />
          </div>
        ) : (
          <>
            {' '}
            {description.stock_descriptions.name}
          </>
        )}
      </span>
      <QuantitySelector
        limit={description.stock}
        onQuantityChange={(value) => onQuantityChange(item, value, description,
          message)}
        quantity={description.quantity}
        description={description}
      />
    </div>
  );
  const renderFullCart = () => (
    <div className="cart" data-testid="full-cart">
      <div className="cart-items">
        <div className="titles-container">
          <div className="cart-name-title">
            <span>Producto</span>
          </div>
          <div className="cart-quantity-title">
            <span>Cantidad</span>
          </div>
          <div className="cart-price-per-unit">
            {size.width > 600 ? (<span>$ x Unidad</span>) : (<span>$ x U</span>)}
            &nbsp;&nbsp;&nbsp;
            <span>$ Total</span>
          </div>
        </div>
        <div className="cart-full-items">
          {items.every((item) => item.item) && !loading ? items.map(({
            item, quantity, descriptions, descriptionId, message,
          }) => (
            <div className="cart-item" key={descriptionId ? `cart-item-${item.id}-${descriptionId}` : `cart-item-${item.id}`}>
              <div className="cart-container">
                <div className="cart-image-container">
                  <img onClick={() => onItemClick(item)} src={item.image1} alt={item.name} role="presentation" />
                </div>
                {size.width > 850 && renderCartComments(item, quantity, message)}
                <div className="quantity-cart">
                  {descriptions ? (
                    <>
                      <Carousel breakPoints={breakPoints}>
                        {descriptions.map(
                          (description) => renderDescriptionCarousel(description, item, message),
                        )}
                      </Carousel>
                    </>
                  ) : (
                    <QuantitySelector
                      limit={item.stock}
                      onQuantityChange={(value) => onQuantityChange(item, value, null, message)}
                      quantity={quantity}
                      description={null}
                    />
                  )}
                </div>
                <div className="cart-product-prices">
                  <span className="cart-item-price">
                    {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                    {!!item.sale
                    && (
                    <>
                      <span className="cart-item-span-price">{`$${item.price.toLocaleString('de-DE')}`}</span>
                      <span className="cart-item-span-discount">{` ${Math.round(100 - (item.sale / item.price) * 100)}% OFF `}</span>
                    </>
                    )}
                  </span>
                  {size.width > 900 && <>&nbsp;&nbsp;&nbsp;</>}
                  <span className="cart-item-price-total">
                    {`$${item.sale ? (item.sale * quantity).toLocaleString('de-DE') : (item.price * quantity).toLocaleString('de-DE')}`}
                  </span>
                </div>
                <div className="cart-product-delete">
                  <Button
                    text={(
                      <span>
                        <img className="cart-remove" src={remove} alt="imagen" />
                      </span>
                    )}
                    onClick={() => onRemoveFromCart(item)}
                    buttonClass="cart-button-remove"
                  />
                </div>
              </div>
              {size.width <= 850 && renderCartComments(item, quantity, message)}
              <div className="separator" />
            </div>
          )) : <Loading />}
        </div>
      </div>
      <div className="separator-v3" />
      {renderCheckoutDetails()}
    </div>
  );

  const renderModalCart = () => (
    <Modal isCart loading={loading} onClose={onClose} title="Mi Carrito">
      <div className="row">
        <div className="col clearfix">
          <div className="container">
            <div className="row">
              <div className="col">
                {!showCart && renderEmptyCart()}
                {showCart && renderFullCart()}
                <div className="monto-min-cart">
                  <h5 className="minimum-purchase">
                    {' '}
                    Monto mínimo: $
                    {minimumPurchase}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  return isModalCart ? renderModalCart() : (
    <div data-testid="page-cart">
      <div className="container">
        <div className="row">
          <div className="col clearfix">
            <Breadcrumb urls={['/carrito']} names={['Carrito']} />
            <div className="row">
              <div className="col">
                {!showCart && renderEmptyCart()}
                {showCart && renderFullCart()}
                <div className="monto-min-cart">
                  <h5 className="minimum-purchase">
                    {' '}
                    Monto mínimo: $
                    {minimumPurchase}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Cart.defaultProps = {
  isModalCart: false,
  priceDiscount: null,
};

Cart.propTypes = {
  cartSize: PropTypes.number.isRequired,
  clearCart: PropTypes.func.isRequired,
  isModalCart: PropTypes.bool,
  items: cartItemsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onMessageChange: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  onRemoveFromCart: PropTypes.func.isRequired,
  priceDiscount: PropTypes.number,
  subtotal: PropTypes.number.isRequired,
};

export default Cart;
